import logo from './resources/MPDWebImages/MPD logo white copy 2.jpg';
import "./resources/CSSFiles/ContactUs.css";

import React from 'react';

class ContactUs extends React.Component {
    render() {
        return (
            <div>
                <div>
                    <hr className='large'></hr>
                    <header class="HTML-header flexible">
                        <h1 className='HTML-header-h1'>Contact Us</h1>
                        <div class='flexible'>
                            <img src={logo} className="White-logo" alt="logo" />
                    <div>
                            <form class='contactForm'>
                                <label for="name">Full Name:</label><br/>
                                <input type="text" id="name" name="name"/><br/>
                                <label for="email">Email Address:</label><br/>
                                <input type="text" id="email" name="email"/><br/>
                                <label for="phone">Phone:</label><br/>
                                <input type="text" id="phone" name="phone"/><br/>
                                <label for="company">Company (optional):</label><br/>
                                <input type="text" id="company" name="company"/><br/>
                                <label for="QuestionorRequest">Question or Request</label>
                                <textarea class='questionorRequest' id="QuestionorRequest"></textarea>
                                <button>Submit Question or Request</button>
                            </form>
                        </div>
                        <br/>

                        </div>
                        <hr className='large'></hr>
                            <span class="HTML-header-text">
                                {/* make this a set of columns or the footer */}
                                <h2>
                                    <strong>Victoria Glass</strong>
                                </h2>
                                <div>
                                    <div class="ContactUs-body">
                                        <p className='Body-paragraph'>Business Phone: 325-704-0325 </p><br />
                                        <h2>Mailing Address</h2>
                                        <hr class="large"></hr>
                                        <list className="content text-left features-text List-body" >
                                            <ul>My Personal Driver TX</ul>
                                            <ul>Glass Global LLC</ul>
                                            <ul>500 E. Whitestone Blvd. #1854</ul>
                                            <ul>Cedar Park, TX 78630</ul>
                                        </list>
                                        <h2>Online</h2>
                                        <hr class="large"></hr>
                                        <list className="content text-left features-text">
                                            <ul>www.MyPersonalDriverTX.com</ul>
                                            <br />
                                            <ul>Victoria@MyPersonalDriverTX.com</ul>
                                            <br />
                                            <ul>GlassGlobalLLC@gmail.com</ul>
                                        </list>

                                    </div>
                                </div>
                            </span>
                    </header>
                </div>
            </div>
        )
    }
}

export default ContactUs;
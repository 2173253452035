import React from "react";
import { Component } from "react";
import "./resources/CSSFiles/SqForm.css"

class SqForm extends Component {
    // componentDidMount() {
    //     const config = {
    //         autoBuild: false,
    //     }
    //     const style = {
    //         "background-color": "#006aff",
    //         "border": "none",
    //         color: "white",
    //         height: "410px",
    //         "text-transform": "uppercase",
    //         "font-family": ['Square Market', "sans-serif"],
    //         "letter-spacing": "1px",
    //         "line-height": "38px",
    //         padding: "0 28px",
    //         "border-radius": "8px",
    //         "font-weight": "500",
    //         "font-size": "14px",
    //         cursor: "pointer",
    //         display: "inline-block",
    //     };
    // };
    render() {
        return (

            <div className="Form-container">
                <div className="Sq-form">
                    {/* <!-- Start Square Appointments Embed code */}

                    <a className="Booking-link" target="_parent" href="https://squareup.com/appointments/book/vi6kd1gns1n7g3/8W6J5XJ1YZG7E/start" rel="nofollow">Request Transportation</a>

                    {/* <!-- End Square Appointments Embed code —> */}
                    <div className="blockholder">
                       <h2 className="blockholder form">Place Holder</h2>

                        {/* <form className="blockholder form">
                            <h4>Personal Information</h4>
                            <label for="name">Name:</label><br />
                            <input type="text" id="name" name="name" /><br />
                            <label for="email">Email Address:</label><br />
                            <input type="text" id="email" name="email" /><br />
                            <label for="phone">Phone:</label><br />
                            <input type="text" id="phone" name="phone" /><br />
                            <label for="company">Company (optional):</label><br />
                            <input type="text" id="company" name="company" /><br />
                            <h4>Pick Up Information</h4>
                            <label for="date">Date:</label><br />
                            <input type="date" id="date" name="date" /><br />
                            <label for="time">Time:</label><br />
                            <input type="time" id="time" name="time" /><br />
                            <label for="pickupaddress">Address:</label><br />
                            <input type="text" id="pickupaddress" name="pickupaddress" /><br />
                            <label for="passengers">Number of Passengers</label><br />
                            <input type="number" id="passengers" name="passengers" /><br />
                            <h4>Destination Address</h4>
                            <label for="destinationaddress">Drop-off Address:</label><br />
                            <input type="text" id="destinationaddress" name="destinationaddress" /><br />
                            <h5>Or Airport Information</h5>
                            <label for="airport">Airport</label><br />
                            <select id="airport" name="airport">
                                <option value="AUS">AUS</option>
                                <option value="AUS South Terminal">AUS South Terminal</option>
                                <option value="Signature">Signature</option>
                                <option value="Atlantic">Atlantic</option>
                                <option value="MiloptiononAir">MiloptiononAir</option>
                                <option value="JSX">JSX</option>
                                <option value="Austin Executive">Austin Executive</option>
                                <option value="DFW">DFW</option>
                                <option value="Love">Love</option>
                                <option value="IAH">IAH</option>
                                <option value="Hobby">Hobby</option>
                                <option value="SAT">SAT</option>
                                <option value="Other">Other</option>
                               
                            </select><br />
                            <label form="airline">Airline</label><br />
                            <select id="airline" name="airline">
                                <option value=""></option>
                                <option value="Aeromexico">Aeromexico</option>
                                <option value="Air Canada">Air Canada</option>
                                <option value="Alaska">Alaska</option>
                                <option value="Allegiant">Allegiant</option>
                                <option value="American">American</option>
                                <option value="Br/itish">Br/itish</option>
                                <option value="Delta">Delta</option>
                                <option value="Emirates">Emirates</option>
                                <option value="FlexJets">FlexJets</option>
                                <option value="Frontier">Frontier</option>
                                <option value="jetBlue">jetBlue</option>
                                <option value="KLM">KLM</option>
                                <option value="Lufthansa">Lufthansa</option>
                                <option value="NetJets">NetJets</option>
                                <option value="Norwegian">Norwegian</option>
                                <option value="Southwest">Southwest</option>
                                <option value="Spirit">Spirit</option>
                                <option value="United">United</option>
                                <option value="Virgin">Virgin</option>
                                <option value="Other">Other</option>
                            </select><br />
                            <label for="flightnumber">Flight Number:</label><br />
                            <input type="text" id="flightnumber" name="flightnumber" /><br />
                            <label for="departurearrival">Departure/Arrival Time:</label><br />
                            <input type="time" id="departurearrival" name="departurearrival" /><br />
                            <label for="tsapreyes">TSA Precheck</label><br />
                            <input type="radio" id="tsapre" name="tsapre">yes </input> <input type="radio" id="tsapre" name="tsapre">no</input><br />
                            <label for="clearyes">Clear</label><br />
                            <input type="radio" id="clear" name="clear">yes </input><input type="radio" id="clear" name="clear">no</input><br />
                            <label for="globalentryyes">Global Entry</label><br />
                            <input type="radio" id="globalentry" name="globalentry">yes </input> <input type="radio" id="globalentry"
                                name="globalentry">no</input><br />
                            <label for="luggage">Number of Checked Bags:</label><br />
                            <input type="number" id="luggage" name="luggage" /><br />
                            <label for="carry">Number of Carry-on Bags</label><br />
                            <input type="number" id="carry" name="carry" />
                        </form> */}
                    </div>
                </div>
            </div>
        )
    }
}

export default SqForm;
import "./resources/CSSFiles/Services.css";
import React from 'react';

class Services extends React.Component {
    render() {
        return (
        <div className="row features">
        <div class="col-md-4 col-md-push-0 col-sm-8 col-sm-push-2 col-xs-10 col-xs-push-1 feature">
          <h2 className='content text-center'>Point - to - Point</h2>
          <list className="content text-center features-text">
            <ul>Airports</ul>
            <ul>Destinations</ul>
            <ul>Concerts</ul>
            <ul>Events</ul>
          </list>
        </div>
        <div class="col-sm-8 col-sm-push-2 col-xs-10 col-xs-push-1 feature">
          <h2 className='content text-center'>Hourly</h2>
          <list className="content text-center features-text">
            <ul>Meetings</ul>
            <ul>Gatherings</ul>
          </list>
        </div>
      </div>
        )
    }
}

export default Services
import bigImage from './resources/MPDWebImages/IMG_9559 copy.JPG';
import './resources/CSSFiles/App.css';
import About from './About'
import ContactUs from './ContactUs';
import Services from './Services'
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import SqSchedulerForm from './SqScheduler';

class Home extends Component {

  // componentDidMount(){
  //   const script = document.createElement('script');
  //   script.src = "https://square.site/appointments/buyer/widget/vi6kd1gns1n7g3/8W6J5XJ1YZG7E.js"
  //   script.async = true;
  //   script.onload = () => this.scriptLoaded();
  //   document.body.getElementsByClassName('App').appendChild(script);
  // }
  render() {
    return (<div className='parent'>
      <nav className='NavBar'>
        <ul className='NavBar'>
          <h3 className='NavItem'><Link style={{ color: 'white' }} to="/">Home</Link></h3>
          <h3 className='NavItem'><Link style={{ color: 'white' }} to="/about">About</Link></h3>
          <h3 className='NavItem'><Link style={{ color: 'white' }} to="/contactus">Contact Us</Link></h3>
          <h3 className='NavItem'><Link style={{ color: 'white' }} to="/services">Services</Link></h3>
          <button>Request Service</button>
        </ul>
      </nav>
      <div className="App">
        <header className="site-header parallax">
          <div class="parallax-wrapper">
            <div class="cover" data-scrollax="properties: { 'translateY': '48%' }">
              <img className='cover img' src={bigImage} alt="MPD with Leare Jet" />
              <div class="cover div heading-content">
                <div class="row heading-title image-text">
                  <h2 class="cover h2">Go Anywhere</h2>
                  <hr class="large" />
                  <h1 class="thin">It's Your <strong>Adventure</strong></h1>
                </div>
                <div class="cover div cover-callout">
                  <div class="row heading-title lower-image-text">
                    <h2 class="cover h2">Stress Free</h2>
                    <hr className='large' />
                    <h1 className='thin'><strong>Private Transportation</strong></h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div>
      <div className="row features">
        <div class="col-md-4 col-md-push-0 col-sm-8 col-sm-push-2 col-xs-10 col-xs-push-1 feature">
          <h2 className='content text-center'>SAFE</h2>
          <p className="content text-center features-text">Background Checked</p>
        </div>
        <div class="col-md-4 col-md-push-0 col-sm-8 col-sm-push-2 col-xs-10 col-xs-push-1 feature">
          <h2 className='content text-center'>Professional</h2>
          <p className="content text-center features-text">Licensed</p>
        </div>
        <div class="col-md-4 col-md-push-0 col-sm-8 col-sm-push-2 col-xs-10 col-xs-push-1 feature">
          <h2 className='content text-center'>Reliable</h2>
          <p className="content text-center features-text">Punctual</p>
        </div>
      </div>
      <hr class='large'></hr>
    </div>)
  }
}

// function componentWillMount(){
//   const scriptTag = document.createElement("script");
//   scriptTag.src = "https://square.site/appointments/buyer/widget/vi6kd1gns1n7g3/8W6J5XJ1YZG7E.js";
//   scriptTag.async = true;
//   document.body.appendChild(scriptTag);
// }

class App extends React.Component {
  render() {
    return (
      <div className='HTML-body'>

        <Home />

        <SqSchedulerForm />
        <Services />
        <About />
        <ContactUs />
        {/* <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/services" element={<Services />} />
        </Routes> */}
      </div>
    );
  }
}

export default App;

import downtownAus from './resources/MPDWebImages/IMG_0897 copy.JPG';
import './resources/CSSFiles/About.css';
import React from 'react';

class About extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: null,
        };
    }
    render() {
        return (
            <div>
                <hr className='large'/>
                <header class="HTML-header flexible">
                    <h1 class="HTML-header-h1">
                        About Us
                    </h1>
                    <div class="flexible">
                        <span class="HTML-header-text">
                            <h2 >Safe | Reliable | Professional</h2>
                            <hr class="large"></hr>
                            <p class="indented-para">
                                We are Safe, Reliable, Professional, Private transportation that removes the stress from your travel.
                                Our chauffeured black car service provides safe and secure transportation in Texas.
                                With personalized service that assures your peace of mind.
                                
                                <p class="indented-para"> Ride private with confidence.
                                Safety and Service that meets your high standards.
                                Your situation is unique, we strive to meet and exceed your needs.
                                </p>
                                <p class="indented-para">Remember to <strong><u>Go Anywhere! It's your adventure!</u> </strong>
                                 Exceptional experience with excellent service,
                                choose <i>My Personal Driver TX </i> to serve on your next adventure.
                                </p>
                            </p>
                            <div className="HTML-header-image">
                            </div>
                        </span>
                        <img src={downtownAus} alt="logo" />
                    </div>
                </header>
            </div>
        );
    }
}
export default About;